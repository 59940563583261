<template>
  <div class="player">
    <div class="player__wrapper">
      <div v-on:click.prevent="playing = !playing"
           class="player__button"
           :class="playing ? 'player__button-playing' : ''">
        <Icon class="player__button-icon"
              :xlink="playing ? 'pause' : 'play'"
              viewport="0 0 20 20"/>
      </div>
      <div>
        <div class="player__progress">
          <div :style="{ width: this.percentComplete + '%' }"
               class="player__seeker"></div>
        </div>
        <Row class="player__time"
             justify="between">
          <div class="player__time-current">{{ this.currentSeconds | convertTimeHHMMSS }}</div>
          <div class="player__time-total">{{ this.durationSeconds | convertTimeHHMMSS }}</div>
        </Row>
      </div>
    </div>
    <audio :loop="innerLoop"
           ref="audiofile"
           preload="auto"
           style="display: none;">
      <source :src="params"
              :type="`audio/ogg`"/>
      <source :src="params"
              :type="`audio/mp3`">
    </audio>
  </div>
</template>

<script>
  export default {
    name: 'AudioPlayer',
    props: {
      params: {
        required: true
      }
    },
    data() {
      return {
        autoPlay: false,
        loop: false,
        audio: undefined,
        currentSeconds: 0,
        durationSeconds: 0,
        innerLoop: false,
        loaded: false,
        playing: false,
        previousVolume: 35,
        showVolume: false,
        volume: 100,
      };
    },
    computed: {
      type() {
        return this.params.split('.')[this.params.split('.').length - 1];
      },
      percentComplete() {
        return parseInt(this.currentSeconds / this.durationSeconds * 100);
      },
      muted() {
        return this.volume / 100 === 0;
      },
    },
    filters: {
      convertTimeHHMMSS(val) {
        const hhmmss = new Date(val * 1000).toISOString()
          .substr(11, 8);

        return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
      },
    },
    watch: {
      playing(value) {
        if (value) {
          return this.audio.play();
        }
        this.audio.pause();
      },
      volume() {
        this.showVolume = false;
        this.audio.volume = this.volume / 100;
      },
    },
    methods: {
      load() {
        if (this.audio.readyState >= 2) {
          this.loaded = true;
          this.durationSeconds = parseInt(this.audio.duration);
          return this.playing = this.autoPlay;
        }

        throw new Error('Failed to load sound file.');
      },
      update() {
        this.currentSeconds = parseInt(this.audio.currentTime);
      },
    },
    created() {
      this.innerLoop = this.loop;
    },
    mounted() {
      this.audio = this.$el.querySelector('audio');
      this.audio.addEventListener('timeupdate', this.update);
      this.audio.addEventListener('loadeddata', this.load);
      this.audio.addEventListener('pause', () => {
        this.playing = false;
      });
      this.audio.addEventListener('play', () => {
        this.playing = true;
      });
    },
  };
</script>
